.label-details {
    font-size: 14px !important;
    color: #A5A5A5;
    font: bold;
}

.dados {
    font-size: 20px !important;
    font-weight: 520 !important;
    color: black;
}

.foto-section img {
    width: 70px !important;
}