.profile-section h1 {
    text-align: center !important;
    color: #333 !important;
}

.profile-section h2 {
    margin-top: 30px !important;
    color: #555 !important;
}

.profile-section p {
    margin-bottom: 10px !important;
    color: #777 !important;
}

.profile-section img {
    max-width: 200px !important;
    height: auto !important;
    display: block !important;
    margin: 20px auto !important;
    border-radius: 50% !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.profile-section {
    background-color: #fff !important;
    padding: 20px !important;
    border-radius: 5px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

.bio {
    font-style: italic !important;
}