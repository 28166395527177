.card {
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    border: none;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
}
.cardio::after {
    content: "";
    position: absolute;
    top: -5px;
    right: 0px;
    width: 0;
    height: 0;
    transform: rotate(-45deg);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #0DCAF0;
  }

.card .card-image {
    position: relative;
    overflow: hidden;
    width: 40%;
    height: 50px;
    margin: 0 auto 0 auto;
    margin-top: -17px;
    border-radius: 6px;
    background: #fff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.card .card-image img {
    width: 70px;
    border-radius: 6px;
    pointer-events: none;
}

.card .card-image .card-caption {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-size: 1.3em;
    text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.img-raised {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .ftr {
    margin-top: 15px;
}

.card .ftr div {
    display: inline-block;
}

.card .ftr .author {
    color: #888;
}

.card .ftr .stats {
    float: right;
    line-height: 30px;
}

.card .ftr .stats {
    position: relative;
    top: 1px;
    font-size: 14px;
}


/* ============ Card Table ============ */

.table {
    margin-bottom: 0px;
}

.card .table {
    padding: 8px 15px 2px 15px;
}


.card .table-info {
    background: linear-gradient(60deg, #26c6da, #0097a7);
}

.table>:not(caption)>*>* {
    background-color: transparent;
}


/* ============ Card Blog ============ */

.card-blog {
    margin-top: 30px;
    /* background-color: #F4E7E6; */
}

.card-blog .card-caption {}

/* ============ Card Author ============ */

.card .author .avatar {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
}

.card .author a {
    color: #333;
    text-decoration: none;
}

.card .author a .ripple-cont {
    display: none;
}

.card-description {
    color: #888;
    text-align: justify;
    text-justify: inter-word !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    overflow: hidden;
    height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

}

.card-caption {
    font-weight: 700;
    font-family: "Roboto Slab", "Times New Roman", serif;
}


/* ============ Text Color ============ */
.text-rose {
    color: #e91e63;
}

.text-gray {
    color: #888;
}

/*---------------------------------------------------------------------- /
FONT AWESOME FA CLASS
----------------------------------------------------------------------- */

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}






.radius-10 {
    border-radius: 10px !important;
}

.border-info {
    border-left: 5px solid #0dcaf0 !important;
}

.border-danger {
    border-left: 5px solid #fd3550 !important;
}

.border-success {
    border-left: 5px solid #15ca20 !important;
}

.border-warning {
    border-left: 5px solid #ffc107 !important;
}

.border-info-users {
    border-left: 5px solid #568C3E !important;
}

.border-info-1 {
    border-left: 5px solid rgb(13, 202, 240, 0.4) !important;
}

.border-danger-1 {
    border-left: 5px solid rgb(253, 53, 80, 0.4) !important;
}

.border-success-1 {
    border-left: 5px solid rgb(93, 209, 129, 0.4) !important;
}

.border-warning-1 {
    border-left: 5px solid rgb(255, 193, 7, 0.4) !important;
}



.card-home {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.bg-gradient-scooter {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
    background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.widgets-icons-2 {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 27px;
    border-radius: 10px;
}

.rounded-circle {
    border-radius: 50% !important;
}

.text-white {
    color: #fff !important;
}

.ms-auto {
    margin-left: auto !important;
}

.bg-gradient-bloody {
    background: #fd3550;
    background: -webkit-linear-gradient(45deg, #fd3550, #f8abab) !important;
    background: linear-gradient(45deg, #fd3550, #f8abab) !important;
}

.bg-gradient-ohhappiness {
    background: #00b09b;
    background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
    background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-blooker {
    background: #ffdf40;
    background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
    background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}


.entity-card {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
    background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.entity-card .bg-gradient-scooter {
    background: #568C3E;
    background: -webkit-linear-gradient(45deg, #568C3E, #fff) !important;
    background: linear-gradient(45deg, #568C3E, #fff) !important;
}

.entity-card .bg-gradient-scooter .card-iconn {
    color: #FFFFFF !important;
}

.entity-card .main-title {
    color: #FFFFFF !important;
}

.entity-card p {
    color: #FFFFFF !important;
}


.texto{
    text-align: left !important;
}